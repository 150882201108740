.donatecharge-page {
  width: 100%;
  height: 100dvh;
  position: relative;
}
.donatecharge-main {
  padding: 80px 17px 33px;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  .charge-title {
    font-family: "Pretendard-Medium";
    font-size: 1.1rem;
    line-height: 1.05rem;
  }
  .charge-input {
    font-size: 1.5rem;
    font-family: "Pretendard-Medium";
    padding: 0.3rem 0;
    border: none;
    border-bottom: 2px solid #e2e2e2;
    margin-top: 22px;
  }
  .charge-input:focus {
    outline: none;
    box-shadow: none;
  }
  .charge-btn-area {
    position: relative;
    flex: 1;
    width: 100%;
  }
  .charge-btn {
    position: absolute;
    bottom: 5rem;
    padding: 0.9rem;
    border-radius: 5px;
    width: 100%;
    background-color: #ffe136;
    text-align: center;
    font-family: "Pretendard-SemiBold";
    font-size: 1.1rem;
  }
}
