.donatechargefinish-page {
  width: 100%;
  height: 100dvh;
  position: relative;
}

.donatechargefinish-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100dvh;
}

.charge-icon-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.charge-icon {
  width: 108px;
  height: 140px;
  margin-bottom: 28px;
  margin-top: 100px;
}

.charge-message {
  font-size: 22px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  font-family: "Pretendard-SemiBold";
}

.button-section {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  gap: 8px; /* 버튼 사이 간격 제거 */
}

.close-btn {
  background-color: #e0e0e0;
  color: #000000;
  width: 140px;
  height: 48px;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  font-family: "Pretendard-SemiBold";
}

.view-btn {
  background-color: #ffe136;
  color: #000000;
  width: 140px;
  height: 48px;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  text-align: center;
  font-family: "Pretendard-SemiBold";
}

.charge-info-section {
  width: 100%;
  border-top: 1px solid #ddd;
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 80px;
}

.charge-info {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.label {
  color: #5c5c5c;
  font-family: "Pretendard-SemiBold";
  font-size: 14px;
}

.value {
  color: #000000;
  font-family: "Pretendard-SemiBold";
  font-size: 18px;
}
