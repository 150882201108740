.thanks-page {
  width: 100%;
  height: 100dvh;
  background-color: white;
  position: relative;

  .thanks-bg {
    padding-top: 52px;
    width: 100vw;
    position: absolute;
    top: 0;
    max-width: 390px;
  }
  .thanks-main {
    padding: 55px 19px 0 19px;
    width: 100%;
    height: 100dvh;
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .letter-input {
      border: none;
      width: 100%;
      font-size: 1.1rem;
      font-family: "Pretendard-Regular";
      outline: none;
    }

    .thanks-letter {
      /* Frame 26086462 */
      width: 100%;
      height: 57%;
      box-sizing: border-box;

      padding: 20px 28px;

      background: #ffffff;
      border: 1px solid #e2e2e2;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
    .thanks-btn {
      margin-top: 3rem;
      padding: 15px;
      width: 50%;
      text-align: center;
      font-size: 1.2rem;
      font-family: "Pretendard-Medium";
      background: #ffe136;
      border-radius: 30px;
    }
  }
}
.thankfinish-page {
  width: 100%;
  height: 100dvh;
  .thankfinish-main {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ok-btn {
    text-align: center;
    margin-top: 5rem;
    font-size: 1.2rem;
    font-family: "Pretendard-Medium";
    width: 30%;
    padding: 15px;
    background: #ffe136;
    border-radius: 30px;
  }
  .finish-img {
    overflow: hidden;
    width: 203px;
  }
}
