.donate-page {
  width: 100%;
  height: 100dvh;

  .donate-page-top {
    padding: 80px 17px 170px 17px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: white;
    .donate-price-box {
      padding: 12px 25px 12px 12px;
      display: flex;
      flex-direction: row;
      gap: 12px;
      border: 1px solid #e2e2e2;
      border-radius: 10px;
      align-items: center;
    }
    .donate-price-img {
      flex: 2.5;
      overflow: hidden;
      border-radius: 5px;
    }
    .donate-price-detail {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 9px;
      .yellow {
        color: #ffb800;
        font-size: 1.1rem;
        font-family: "Pretendard-SemiBold";
      }
      .date {
        color: #999999;
        font-size: 0.8rem;
        font-family: "Pretendard-Regular";
      }
    }
    .donate-checkbox {
      flex: 0.5;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .donate-btn-area {
    position: fixed;
    bottom: 65px;
    width: 100%;
    max-width: 390px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.8rem 0.9rem;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
    .donate-btn {
      border-radius: 5px;
      width: 100%;
      padding: 14px 0;
      text-align: center;
      font-family: "Pretendard-SemiBold";
      font-size: 1.1rem;
      background-color: #ffe136;
    }
  }
}
.donatelist-page {
  width: 100%;
  height: 100dvh;
  .donatelist-main {
    padding: 75px 15px 50px 15px;
    display: flex;
    flex-direction: column;
    .sort-img {
      width: 69px;
      margin-bottom: 14px;
    }
  }
}

.donator-price-box {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 12px;
  border-bottom: solid #d8d8d8 1px;
  padding-bottom: 13px;
  margin-bottom: 13px;
}
.donate-price-img {
  height: 80px;
  border-radius: 4.34px;
  overflow: hidden;
}
.donate-price-detail {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.donate-place-title {
  font-family: "Pretendard-Medium";
  font-size: 1.1rem;
  color: #5c5c5c;
}
.donate-status {
  text-align: right;
  font-family: "Pretendard-SemiBold";
  font-size: 1.1rem;
  color: #ffb600;
}
.ing {
  color: #ff5f5f;
}
.donate-price-title {
  font-family: "Pretendard-SemiBold";
  font-size: 1.1rem;
}
.price-donator {
  color: #999999;
  font-size: 0.9rem;
}
.donate-price-date {
  margin-top: 15px;
  color: #cfcfcf;
  font-size: 0.9rem;
  margin-left: auto;
}

// donate detail 페이지
.donate-detail-page {
  width: 100%;
  height: 100dvh;
  .donate-detail-main {
    display: flex;
    flex-direction: column;
  }

  .detail-top {
    padding: 80px 15px 0 15px;
    .store {
      color: #b3b3b3;
      font-size: 0.8rem;
      font-family: "Pretendard-Regular";
      margin-bottom: 4px;
      text-align: center;
    }
    .price {
      font-family: "Pretendard-Medium";
      font-size: 1.1rem;
      text-align: center;
    }
    .price-img {
      width: 300px;
      margin: 30px auto;
      text-align: center;
    }
  }
  .donate-detail-mid {
    padding: 0 15px;
    border-bottom: solid #e2e2e2 7px;
  }
  .letter-zone {
    padding: 22px 15px 80px 15px;
    .letter-area {
      position: relative;
    }
    .letter-title {
      font-family: "Pretendard-SemiBold";
      font-size: 1.2rem;
    }
    .letter-img {
      margin-top: 28px;
      width: 100%;
    }
    .area {
      padding: 15px;
      width: 100%;
      position: absolute;
      z-index: 10;
      bottom: 5px;
    }
    .text-box {
      padding: 18px;
      width: 100%;
      background-color: white;
      /* position: absolute; */
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .profile {
        display: flex;
        align-items: center;
        gap: 14px;
      }
      .profile-img {
        width: 42px;
        height: 42px;
      }
      .child {
        font-family: "Pretendard-SemiBold";
        font-size: 1.37rem;
      }
      .message {
        font-family: "Pretendard-Medium";
        font-size: 1rem;
      }
    }
  }
}

.donate-detail {
  width: 100%;
  padding: 22px 0;
  border-top: solid #e2e2e2 2px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  .detail-txt {
    font-family: "Pretendard-Regular";
    font-size: 0.9rem;
    color: #999999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .medium {
    font-family: "Pretendard-Medium";
    color: #5c5c5c;
    font-size: 1rem;
  }
  .black {
    color: #000000;
    font-size: 1.2rem;
  }
}
