.content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  .home-container {
    width: 100%;
    max-width: 390px;
    margin: 0 auto;
    margin-top: -50px;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 70px;
      padding-bottom: 10px;

      .logo {
        height: 19px;
        width: auto;
        margin-left: 15px;
      }

      .icons {
        display: flex;
        gap: 16px;

        .icon {
          width: 45px;
          height: 45px;
        }
      }
    }
    .real-header {
      padding: 60px 5px 10px 5px !important;
    }
    .banner-container {
      position: relative;
      overflow: hidden;
      height: 220px;
      .banner-container {
        overflow: hidden;
        width: 100%;
        height: 200px; /* 배너 높이 */
        position: relative;
      }

      .banner-slider {
        display: flex;
        width: 100%; /* 배너 개수에 따라 조정 */
      }

      .banner-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .banner {
        display: flex;
        width: 100%;

        img {
          flex: 0 0 100%;
          width: 100%;
          height: 212px;
          object-fit: cover;
        }
      }
    }

    .location {
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 10px 0;
      padding-left: 90px;
      margin-top: -20px;
      border-radius: 10px;
      background-image: url("../../img/Main/locate.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 93vw;
      color: #000;
      position: relative;
      z-index: 1000;
    }

    .reviews,
    .recent-shops {
      margin: 15px 0 10px 0;
      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          font-size: 18px;
          margin-left: 13px;
          margin-bottom: 10px;
        }
      }

      .review-list,
      .shop-list {
        display: flex;
        overflow-x: auto;
        gap: 16px;
        padding-bottom: 10px;
      }
    }

    .divider {
      height: 5px;
      background-color: #efefef;
      margin: 10px 0;
    }

    .recent-shops {
      margin-bottom: 5rem;
      h3 {
        margin-left: 13px;
        padding-left: 3px;
        margin-bottom: -5px;
        font-size: 18px;
      }
    }
  }

  // 리뷰
  .review-card {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding: 11px;
    border: 0.1px solid rgb(172, 172, 172);
    border-radius: 8px;
    width: 155px;
    height: 100px;
    text-align: left;
    margin: 10px -15px 0 12px;

    .header {
      display: flex;
      margin: -70px 0 3px;

      .profile-and-info {
        display: flex;
        align-items: center;

        .profile-img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .user-info {
          display: flex;
          flex-direction: column;

          .store-name {
            font-size: 10px;
            font-family: "Pretendard-Medium";
            margin: 0;
          }

          .nickname-and-details {
            display: flex;
            align-items: center;
            gap: 5px;

            .nickname {
              font-size: 8px;
              color: #555;
            }

            .user-details {
              font-size: 8px;
              color: #888;
            }
          }

          .rating-info {
            display: flex;
            align-items: center;
            margin-top: 4px;

            .stars {
              display: flex;

              .star-icon {
                width: 7px;
                height: 6px;
                margin-right: 1px;
              }
            }

            .date {
              font-size: 6px;
              color: #888;
              margin-left: 5px;
            }
          }
        }
      }
    }

    .description {
      font-size: 10px;
      color: #333;
      margin-top: 3px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .review-list {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding-bottom: 10px;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .review-card {
      flex: 0 0 100%;
    }
  }

  // 최근 방문한 가게
  .review-list,
  .shop-list {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding-bottom: 10px;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .review-card,
    .shop-card {
      flex: 0 0 auto;
    }
  }

  .shop-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 150px;
    margin: 15px -13px 0 15px;

    .shop-image {
      width: 155px;
      border-radius: 8px;
      margin-bottom: 5px;
    }

    .info-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      gap: 5px;

      .shop-name {
        font-size: 12px;
        font-family: "Pretendard-Regular";
      }

      .rating {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #666;

        .star-icon {
          width: 12px;
          height: 12px;
          margin-right: 2px;
        }
      }
    }
  }
}
//애니메이션
@keyframes popUp {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
//후원자 홈 화면

//내 후원
.donation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  margin: 10px auto;
  width: 160px;
  height: auto;
  margin-left: -10px;
  .user-avatar {
    width: 100%;
    animation: popUp 2s 3;
  }
  .user-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    margin-right: 20px;

    .user-details {
      margin-bottom: 10px;

      h3 {
        margin: 0;
        font-size: 12px;
        font-family: "Pretendard-Medium";
        color: #333;
      }

      p {
        margin: 5px 0;
        font-size: 9px;
        color: #666;
      }
    }

    .user-stats {
      p {
        margin: 5px 0;
        font-size: 7px;
        color: #666;

        span {
          font-family: "Pretendard-Medium";
          color: #ffd700;
        }
      }
    }
  }

  .highlight-yellow {
    color: #ffd700;
  }
}

.profile-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

//랭크
.rank-container {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-top: -3px;
}

/* rank-list 관련 스타일 */
.rank-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* @keyframes 정의 */
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.rank-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0;
  opacity: 0;
  transform: translateY(20px); /* 초기 위치 */
  animation: slide-up 0.5s ease-out forwards;

  &:last-child {
    border-bottom: none;
  }
}

.rank-left {
  display: flex;
  align-items: center;
  margin-bottom: -5px;

  .rank-number {
    font-size: 13px;
    font-family: "Pretendard-SemiBold";
    margin-right: 10px;
  }

  .rank-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .rank-info {
    display: flex;
    flex-direction: column;

    .rank-name {
      font-size: 10px;
      font-family: "Pretendard-SemiBold";

      .rank-gender {
        font-size: 7px;
        color: #888;
        margin-left: 3px;
      }
    }

    .rank-date {
      font-size: 6px;
      color: rgb(255, 193, 7);
      margin-top: 3px;
    }
  }
}

.rank-right {
  text-align: right;
  display: flex;
  align-items: flex-end;
  gap: 5px;

  .rank-amount {
    font-size: 9px;
    color: #aaa;
    margin-left: 10px;
  }

  .rank-total {
    font-size: 10px;
    font-family: "Pretendard-SemiBold";
    color: #333;
  }
}

.reviews-content {
  display: flex;
  margin-bottom: -30px;
  overflow: hidden;
  flex-wrap: nowrap;
  cursor: pointer;
}

.rank-card {
  margin-left: -50px;
  margin-top: -17px;
}

.donation-card {
  margin-top: -25px;
  margin-left: 10px;
  z-index: 100;
}

.reviews {
  overflow: hidden;
}
.donate-page {
  width: 100%;
  height: 100dvh;

  .donate-page-top {
    padding: 80px 17px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .donate-price-box {
      padding: 12px 25px 12px 12px;
      display: flex;
      flex-direction: row;
      gap: 12px;
      border: 1px solid #e2e2e2;
      border-radius: 10px;
    }
    .donate-price-img {
      flex: 2.5;
      overflow: hidden;
      border-radius: 5px;
    }
    .donate-price-detail {
      flex: 2;
      display: flex;
      flex-direction: column;
      gap: 9px;
      .yellow {
        color: #ffb800;
        font-size: 1.1rem;
        font-family: "Pretendard-SemiBold";
      }
      .date {
        color: #999999;
        font-size: 0.8rem;
        font-family: "Pretendard-Regular";
      }
    }
    .donate-checkbox {
      flex: 0.5;

      display: flex;
      align-items: center;
      text-align: right;
      input {
        margin-left: auto;
        width: 20px;
        height: 20px;
        border-radius: 999px;
      }
    }
  }

  .donate-btn-area {
    position: fixed;
    bottom: 65px;
    width: 100%;
    max-width: 390px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.8rem 0.9rem;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
    .donate-btn {
      border-radius: 5px;
      width: 100%;
      padding: 14px 0;
      text-align: center;
      font-family: "Pretendard-SemiBold";
      font-size: 1.1rem;
      background-color: #ffe136;
    }
  }
}

.reviews {
  .section-header {
    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  .rank-icons {
    display: flex;
    width: 100%;
    margin-top: 10px;

    .rank-icon {
      height: auto;
      margin-left: 15px;
    }
  }
}

.scroll-hidden-but-scrollable {
  overflow: auto;
}

.scroll-hidden-but-scrollable::-webkit-scrollbar {
  display: none;
}

.scroll-hidden-but-scrollable {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

//통계
.region-stats {
  padding: 15px;
  border-radius: 12px;
  background-color: #fff;

  &-header {
    h3 {
      font-size: 1.2rem;
      font-family: "Pretendard-SemiBold";
    }
  }

  &-tabs {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;

    .region-tab {
      padding: 5px 15px;
      font-size: 12px;
      border: 1px solid #ddd;
      border-radius: 20px;
      background-color: #f9f9f9;
      cursor: pointer;
      transition: all 0.3s ease;
      white-space: nowrap;
      margin-right: 5px;

      &.active {
        background-color: #fff;
        border: 2px solid #ffc107;
        color: #ffc107;
        font-family: "Pretendard-SemiBold";
      }
    }
  }

  &-content {
    margin-top: 15px;
    padding: 7px;
    border: 0.1px solid #c2c2c2;
    border-radius: 15px;

    .region-stats-title {
      font-size: 11px;
      color: #8b8b8b;
      margin-bottom: 10px;
      font-family: "Pretendard-Medium";
      text-align: right;
    }

    .region-stats-graph {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .region-stats-chart {
        flex: 3;
        display: flex;
        position: relative;
        height: 150px;
        margin-right: 20px;

        .chart-background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .chart-line {
            position: relative;
            border-top: 1px dashed #ddd;
            width: 100%;
          }
        }

        .chart-bar {
          flex: 1;
          text-align: center;
          position: relative;
          margin: 0 5px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .chart-bar-inner {
            width: 70%;
            border-radius: 30px;
            transition: height 0.3s ease;
            background-color: #ccc;
            position: relative;
          }

          .chart-bar-inner.highlight {
            background-color: #ffc107;
          }

          .chart-value {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -130%);
            font-size: 7px;
            font-size: "Pretendard-Medium";
            color: #555;
          }

          .chart-label {
            margin-top: 10px;
            font-size: 8px;
            color: #555;
            margin-left: -10px;
          }
        }
      }

      .region-stats-list {
        flex: 2;
        list-style: none;
        padding: 0;
        margin-top: 20px;

        .region-stat-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 10px;
          color: #000000;
          font-size: 12px;
          margin-left: -10px;

          span {
            margin-right: 10px;

            &:first-child {
              font-family: "Pretendard-Bold";
            }

            &:last-child {
              margin-left: auto;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.region-stats .region-stats-chart.custom-chart {
  flex-direction: column;
  align-items: flex-start;

  .chart-bar-inner {
    background-color: #ffa726;
  }
}

.horizontal-chart {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 330px;
  margin-top: 10px;
}

.horizontal-bar-container {
  display: flex;
  align-items: center;
}

.bar-rank {
  font-size: 12.7px;
  font-family: "Pretendard-SemiBold";
  color: #000000;
  margin-right: 10px;
  margin-left: 5px;
}

.horizontal-bar {
  flex: 1;
  height: 22px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.bar {
  width: 0%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-radius: 15px;
  color: #fff;

  font-size: 12px;
  transition: width 1s ease-in-out;
  .bar-value {
    font-family: "Pretendard-Regular";
    font-size: 0.75rem;
  }
}
