.mypage-page {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;
    background-color: white;
    position: relative; 
}

.mypage-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    position: relative; 
    top: 0;
    max-width: 360px; 
    width: 100%;
}

.mypage-header h1 {
    text-align: center;
    font-size: 22px;
    font-family: "Pretendard-SemiBold";
}

.header-icons img {
    gap: 10px; 
}

.searchImg {
    cursor: pointer;
}

.alarmImg {
    cursor: pointer;
}

.profile-section {
    display: flex;
    align-items: center;
    padding: 15px 0;
    width: 100%;
    flex-direction: row;
    margin-top: 20px;
}

.profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
  }

.profile-info h2 {
    font-size: 18px;
    font-family: "Pretendard-SemiBold";
}

.profile-info2{
  align-items: center;
}

.profile-info p {
  font-size: 10px;
  color: #B3B3B3;
}

.badge {
    background: #FFF5BA;
    color: #FFB800;
    border-radius: 5px;
    margin-left: 10px;
    font-size: 8px;
    font-family: "Pretendard-SemiBold";
}

.stats-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px; 
  border: 1px solid #E2E2E2; 
  border-radius: 12px; 
  width: 100%; 
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1; 
  text-align: center;
}

.stat:not(:last-child) {
  border-right: 1px solid #eaeaea; 
}

.stat img {
  width: 28px; 
  height: 28px;
  margin-bottom: 8px; 
}

.stat p {
  font-size: 14px; 
  font-weight: bold;
  margin: 0;
  color: #000; 
}

.stat p:first-of-type {
  font-size: 12px; 
  color: #B3B3B3; 
  margin-bottom: 4px;
}

.donatego{
  cursor: pointer;
}
.menu-selection-2{
   background: white;
    padding: 0 18px 80px 18px;}
.menu-section {
  margin-top: 20px;

  h3 {
    font-size: 12px;
    margin-bottom: 12px;
    color: #b3b3b3;
    font-family: "Pretendard-SemiBold";
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      padding: 18px 0;
      border-bottom: 1px solid #eaeaea;

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      span {
        flex: 1;
        font-size: 14px;
        font-family: "Pretendard-SemiBold";
        color: #5C5C5C;
      }

      img:last-child {
        width: 10px;
        height: 10px;
      }
    }
  }
}
