.navbar {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  max-width: 390px;
  height: 74px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #ddd;
  z-index: 1000;
}

.navbar a {
  text-decoration: none;
  color: #b0b0b0;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar img {
  width: 72px;
  height: 60px;
  margin-bottom: 4px;
}

.heart-page {
  padding: 20px;
  position: relative;
  overflow-x: hidden;

  .page-title {
    font-size: 20px;
  }

  .back-icon {
    position: absolute;
    top: 66px;
    left: 10px;
    width: 19px;
    height: 19px;
    cursor: pointer;
  }

  .heart-title {
    font-size: 20px;
    margin: 50px 0 10px 10px;
    font-weight: bold;
  }

  .restaurant-list-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 20px;
    width: 100%;
  }

  .list-card {
    display: flex;
    margin-bottom: 12px;
    background-color: #fff;
    position: relative;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;

    .card-image {
      width: 148px;
      height: 124px;
      object-fit: cover;
      border-radius: 10px;
      margin-right: 10px;
    }

    .card-details {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 8px 0;

      .title-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1px;
        .title-and-rating {
          display: flex;
          align-items: center;
          gap: 8px;

          .title {
            font-size: 13.9px;
            font-weight: bold;
            color: #333;
          }
        }

        .favorite-icon {
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
      }

      .location-row {
        display: flex;
        align-items: center;
        font-size: 10px;
        color: #aaaaaa;
        margin-top: 5px;

        .location-icon {
          width: 11px;
          height: 11px;
          margin-right: 4px;
        }
      }

      .distance-row {
        font-size: 10px;
        margin-top: 5px;
        color: #666;
      }

      .review-row {
        font-size: 10px;
        margin-top: 5px;
        color: #999;
      }

      .like-row {
        font-size: 10px;
        margin-top: 5px;
        color: #999;
      }

      .donation-row {
        font-size: 10px;
        text-align: right;
        margin-top: 15px;

        span {
          &.label {
            color: #333;
          }

          &.amount {
            color: #ff9900;
            font-weight: bold;
          }
        }
      }
    }
  }
}
