* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
* {
  font-family: "Pretendard-Regular", sans-serif; 
}

body,
#root {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  @media (max-width: 390px) {
    font-size: 3.2vw;
  }
}

// 폰트 :프리텐다드 (regular-medium-semibold-bold)
@font-face {
  font-family: "Pretendard-Bold";
  src: url("https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Bold.otf")
      format("opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff2https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Bold.woff2")
      format("woff2"),
    url("https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Bold.woff")
      format("woff");
  font-style: normal;
}

@font-face {
  font-family: "Pretendard-Medium";
  src: url("https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Medium.otf")
      format("opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff2https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Medium.woff2")
      format("woff2"),
    url("https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Medium.woff")
      format("woff");
  font-style: normal;
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Regular.otf")
      format("opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff2https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Regular.woff2")
      format("woff2"),
    url("https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Regular")
      format("woff");
  font-style: normal;
}

@font-face {
  font-family: "Pretendard-SemiBold";
  src: url("https://cdn.banggooso.com/sr/assets/fonts/Pretendard-SemiBold.otf")
      format("opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff2https://cdn.banggooso.com/sr/assets/fonts/Pretendard-SemiBold.woff2")
      format("woff2"),
    url("https://cdn.banggooso.com/sr/assets/fonts/Pretendard-SemiBold")
      format("woff");
  font-style: normal;
}

// 이미지 100%
.img-width {
  width: 100%;
}
/* 웹앱 형태 고정 */

body::-webkit-scrollbar {
  display: none;
}

.common-layout {
  height: 100dvh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.app-main {
  background-color: white;
  max-width: 390px;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (max-width: 390px) {
    width: 100%;
  }
}

.app-container {
  width: 390px;
  height: 844px;
  overflow: hidden;
  background-color: #fff;
}
