.signup-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px;
    width: 100%;
    background-color: white;
    position: relative; 
  }
  
.signup-header {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    padding: 17px 0; 
    background-color: #ffffff;
    position: relative; 
    max-width: 360px; 
    top: 0;
    width: 100%;
  }
  
.signup-header h1 {
    text-align: center;
    font-size: 18px;
    margin-left: 18px;
    font-family: "Pretendard-SemiBold";
  }
  
  .back-button {
    cursor: pointer;
  }
  
  .textview {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }

  .welcome-text {
    display: flex;
    text-align: center;
    margin: 68px 0;
    font-size: 18px;
    color: #000000;
    font-family: "Pretendard-medium";
  }
  
  
  .type-selection {
    display: flex;
    width: 100%;
    justify-content: center; 
    gap: 18px;
    align-items: center;
  }
  
  .type-card img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 213px;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }
  
  .submit-button {
    display: flex;
    align-items: center;
    bottom:0;  
    background-color: #fdd835;
    justify-content: center;
    height:47px;
    width:350px;
    border: none;
    color: #000000;
    font-size: 16px;
    border-radius: 5px;
    font-family: "Pretendard-SemiBold";
    cursor: pointer;
    margin-top: 80px;
    position: relative;
  }
  
  .submit-button:hover {
    background-color: #fbc02d;
  }
  
