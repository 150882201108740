.map-container {
  position: relative;
  width: 100%;
  height: 92vh;
  overflow: hidden;

  .top-bar {
    position: absolute;
    left: 0;
    width: 100vw;
    max-width: 390px;
    height: 100px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin-top: -40px;

    .icon {
      margin-top: 45px;

      &.search-icon {
        margin-left: 57%;
      }

      &.back-icon {
        margin-right: 25px;
        height: 18px;
      }

      &.alarm-icon {
        margin-left: 5px;
      }
    }
  }

  .search-bar {
    position: absolute;
    top: 80px;
    left: 18px;
    right: 16px;
    display: flex;
    align-items: center;
    width: 90%;
    height: 44px;
    padding: 0 10px;
    background-color: #f9f9f9;
    border: 1px solid #ffd000;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    input {
      flex: 1;
      border: none;
      background: transparent;
      font-size: 14px;
      color: #333;
      outline: none;
    }

    .icon {
      width: 30px;
      height: 30px;
      margin-left: 8px;
    }
  }

  .map {
    width: 100%;
    height: calc(100vh - 60px);
    position: absolute;
  }

  .locate-icon {
    position: absolute;
    left: 80%;
    bottom: 10dvh;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: top 0.3s ease;

    &.card-visible {
      z-index: -1;
    }
  }
}

//
.bottom-card {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 359px;
  margin-bottom: 100px;
  background-color: white;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 20px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h3 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
    white-space: nowrap;
    overflow: hidden;
  }

  .card-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    overflow: hidden;

    .icon {
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }

    p {
      font-size: 14px;
      color: #555;
      margin: 1px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

//추가
.banner {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  justify-content: space-between;
  overflow: visible;
}

.card-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.prev-btn,
.next-btn {
  background: rgba(255, 255, 255, 0.9);
  border: none;
  font-size: 17px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  position: relative;
  top: -110px;
  z-index: 1000;
  color: #333;
}
